import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./payment.scss";
import { useSelector } from "react-redux";
import CheckoutForm from "../../components/checkOut/CheckoutForm";
import axios from "axios";
import Succsess from "../../components/payment/Succsess";

const Payment = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState("");
  const [
    destination_address_mobile_number,
    setDestination_address_mobile_number,
  ] = useState("");

  const [destination_address_house_no, setDestination_address_house_no] =
    useState("");
  const [destination_address_area, setDestination_address_area] = useState("");
  const [destination_address_landmark, setDestination_address_landmark] =
    useState("");
  const [destination_address_city, setDestination_address_city] = useState("");

  const [customer_reference_number, setCustomer_reference_number] =
    useState("");

  const [showCheckout, setShowCheckout] = useState(false);

  const [sessionId, setSessionId] = useState(null);

  const generateUniqueNumber = () => {
    const uniqueNumber = "NOT-" + Date.now();
    setCustomer_reference_number(uniqueNumber);
  };

  useEffect(() => {
    generateUniqueNumber();
  }, []);

  const products = useSelector((state) => state.cart.products);

  const delivaryAmout = 20;
  const delivaryAmoutLess = 10;
  const noDelivaryAmout = 0;

  const totals = products.reduce(
    (acc, product) => {
      const price = parseFloat(product.price);
      const quantity = parseInt(product.quantity, 10);

      acc.totalPrice += price * quantity;
      acc.totalItems += quantity;

      return acc;
    },
    { totalPrice: 0, totalItems: 0 }
  );

  const netOder = totals.totalPrice;

  const withDelivaryCost = () => {
    if (netOder < 50) {
      const netTotal = netOder + delivaryAmout;
      return netTotal.toFixed(2);
    }
    if (netOder >= 50 && netOder < 75) {
      const netTotal = netOder + delivaryAmoutLess;
      return netTotal.toFixed(2);
    }
    if (netOder >= 75) {
      const netTotal = netOder + noDelivaryAmout;
      return netTotal.toFixed(2);
    } else {
      console.log("all good");
    }
  };

  const finalCost = withDelivaryCost();

  const productsData = {
    totals: finalCost,
    qut: totals.totalItems,
    fName,
    email,
    price: finalCost,
    name: fName,
    title: fName,
    quantity: totals.totalItems,
  };

  const placeOrder = (e) => {
    e?.preventDefault();
    const orderData = {
      destination_address_name: fName + " " + lName,
      email,
      products,
      num_pieces: productsData.qut,
      card_amount: productsData.totals,
      descripon: products.title,
      destination_address_city,
      destination_address_landmark,
      destination_address_area,
      destination_address_building_name: destination_address_house_no,
      destination_address_house_no,
      customer_reference_number,
      destination_address_mobile_number,
      weight: 2,
    };

    localStorage.setItem("orderData", JSON.stringify(orderData));
  };

  const showCheckoutSection = (e) => {
    e.preventDefault();
    setShowCheckout(true);
    placeOrder();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
    setSessionId(sessionId);
  }, []);

  useEffect(() => {
    const updateOrder = async () => {
      // const status = "success";
      if (!sessionId) {
        return;
      }
      const savedData = localStorage.getItem("orderData");
      if (savedData) {
        const orderData = JSON.parse(savedData);
        try {
          await axios.post("/create_order", orderData);
        } catch (error) {
          console.log(error);
        }
      }
    };
    updateOrder();
  }, [sessionId]);

  useEffect(() => {
    const savedDataString = localStorage.getItem("orderData");
    if (!sessionId) {
      return;
    }


     const getCurrentDateTime = () => {
       const now = new Date();
       const year = now.getFullYear();
       const month = String(now.getMonth() + 1).padStart(2, "0");
       const day = String(now.getDate()).padStart(2, "0");
       return `${year}-${month}-${day}`;
     };

     const pickupDate = getCurrentDateTime();



    if (savedDataString) {
      const savedData = JSON.parse(savedDataString);
      const jeeblyData = {
        delivery_type: "Next Day",
        load_type: "Non-document",
        consignment_type: "FORWARD",
        description: "Notions Group",
        weight: 1,
        payment_type: "Prepaid",
        card_amount: "50",
        num_pieces: 3,
        customer_reference_number: savedData.customer_reference_number,
        origin_address_name: savedData.destination_address_name,
        origin_address_mob_no_country_code: "",
        origin_address_mobile_number:
          savedData.destination_address_mobile_number,
        origin_address_alt_ph_country_code: "",
        origin_address_alternate_phone: "",
        origin_address_house_no: savedData.destination_address_house_no,
        origin_address_building_name:
          savedData.destination_address_building_name,
        origin_address_area: savedData.destination_address_area,
        origin_address_landmark: savedData.destination_address_landmark,
        origin_address_city: savedData.destination_address_city,
        origin_address_type: "Normal",
        destination_address_name: savedData.destination_address_name,
        destination_address_mob_no_country_code: "",
        destination_address_mobile_number:
          savedData.destination_address_mobile_number,
        destination_details_alt_ph_country_code: "",
        destination_details_alternate_phone:
          savedData.destination_address_mobile_number,
        destination_address_house_no: savedData.destination_address_house_no,
        destination_address_building_name:
          savedData.destination_address_building_name,
        destination_address_area: savedData.destination_address_area,
        destination_address_landmark: savedData.destination_address_landmark,
        destination_address_city: savedData.destination_address_city,
        destination_address_type: "Normal",
        pickup_date: pickupDate,
      };

      axios
        .post(
          "https://myjeebly.jeebly.com/customer/create_shipment",
          jeeblyData,
          {
            withCredentials: true,
            headers: {
              "X-API-KEY": "JjEeEeBbLlYy1200",
              client_key: "5553X240611014030Y617a68617269736d61696c",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Shipment created successfully:", response.data);
        })
        .catch((error) => {
          console.log("Error creating shipment:", error);
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
          }
        });
    } else {
      console.log("No order data found in local storage.");
    }
  }, [sessionId]);

  return (
    <div className="max-width">
      {!sessionId ? (
        <>
          <div className="payment">
            <div className="pay_info">
              <div className="pro_list">
                <div>
                  <h5>Order summary</h5>
                </div>
                {products.length > 0 ? (
                  <div className="all_pro_list">
                    {products.map((product, index) => (
                      <div className="pro_card" key={index}>
                        <div className="img_wrp">
                          <img
                            src={process.env.REACT_APP_UPLOAD_URL + product.img}
                            alt=""
                          />
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>{product.title}</small>
                          </span>
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>Qut:</small> {product.quantity}
                          </span>
                        </div>
                        <div className="info_wrp">
                          <span>
                            <small>AED </small> {product.price.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No products in the cart</p>
                )}
                <div className="total_wrp bg">
                  <span>Have coupon ? </span>
                  <input placeholder="ABC123" type="text" />
                </div>
                <div className="total_wrp">
                  <span>Items Total</span>
                  <span>AED {netOder.toFixed(2)}</span>
                </div>
                <div className="total_wrp">
                  <span>Delivery Charges</span>
                  <span>
                    <>
                      {netOder < 50 ? (
                        <span>AED 20.00</span>
                      ) : netOder >= 50 && netOder < 75 ? (
                        <span>AED 10.00</span>
                      ) : (
                        <span>Free</span>
                      )}
                    </>
                  </span>
                </div>
                <hr />
                <div className="total_wrp">
                  <h4>Total</h4> <h4>AED {withDelivaryCost()}</h4>{" "}
                </div>
              </div>
              <div className="check_form">
                <div>
                  <strong>Customer details</strong>
                </div>
                <form onSubmit={showCheckoutSection}>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>First name</label>
                      <input
                        required
                        type="text"
                        value={fName}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Last name</label>
                      <input
                        required
                        type="text"
                        value={lName}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>Email</label>
                      <input
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Phone Number</label>
                      <input
                        required
                        value={destination_address_mobile_number}
                        type="text"
                        onChange={(e) =>
                          setDestination_address_mobile_number(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>City</label>
                      <input
                        required
                        type="text"
                        value={destination_address_city}
                        onChange={(e) =>
                          setDestination_address_city(e.target.value)
                        }
                      />
                    </div>

                    <div className="input_wrp">
                      <label>Address</label>
                      <input
                        required
                        type="text"
                        value={destination_address_area}
                        onChange={(e) =>
                          setDestination_address_area(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>House No. or Name</label>
                      <input
                        required
                        type="text"
                        value={destination_address_house_no}
                        onChange={(e) =>
                          setDestination_address_house_no(e.target.value)
                        }
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Landmark</label>
                      <input
                        required
                        type="text"
                        value={destination_address_landmark}
                        onChange={(e) =>
                          setDestination_address_landmark(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="pay_btn">
                    <button className="checkout">Continue</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="pay_card">
              {showCheckout && <CheckoutForm productsData={productsData} />}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Succsess />
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
