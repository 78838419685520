import React from "react";
import "./Coconut.scss";
import Newsletter from "../../components/Newsletter/Newsletter";
import AldImg from "../../img/coco_banner.webp";
import CocoHero from "../../img/Coco_hero.webp";
import { Link } from "react-router-dom";

const Coconut = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="coco_sug">
      <div
        className="coco"
        style={{
          backgroundImage: `url(${CocoHero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1>
          Health <br /> alternative <br /> To table <br /> Sugar
        </h1>
      </div>
      <div className="coco_intro">
        <div className="coco_intro_data">
          <h1>DID YOU KNOW ?</h1>
          <strong>Coconut sugar leaves refined sugar in its dust</strong>
          <p>
            Delicious, easy to bake with and basking in unrefined glory, our
            coconut sugar is wholly superior to refined sugar. Why? Refined
            sugar is essentially nutrient-free, while sugar derived from
            coconuts retains the healthy antioxidants they contain. That’s
            because it’s the evaporated, granulated sap of the tender coconut
            flower, from which is expressed the sacred nectar of the coconut
            palm.
            <br />
            <br />
            Coconut sugar keeps blood glucose and energy levels up, and reduces
            blood sugar spikes. Plus, the inulin in coconut sugar slows glucose
            absorption, making it an untouchable, clean-eating, all- purpose
            baking sweetener.
          </p>
        </div>
      </div>
      <div className="coco_be">
        <h1>Health Benefits</h1>
        <div className="coco_be_in">
          <div className="coco_icon_con">
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_1.png")} />
              </div>
              <div>
                <p>Short chain Fifty acids</p>
              </div>
            </div>
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_2.png")} />
              </div>
              <div>
                <p>Less Sucrose</p>
              </div>
            </div>
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_3.png")} />
              </div>
              <div>
                <p>May benefit blood Sugar control</p>
              </div>
            </div>
          </div>

          <div className="coco_img">
            <img src={require("../../img/coco_ban.webp")} />
          </div>

          <div className="coco_icon_con">
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_4.png")} />
              </div>
              <div>
                <p>Small amounts Of antioxidants</p>
              </div>
            </div>
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_5.png")} />
              </div>
              <div>
                <p>Rich in Minerals</p>
              </div>
            </div>
            <div className="coco_icon">
              <div className="co_icon">
                <img src={require("../../img/coco_6.png")} />
              </div>
              <div>
                <p>Mood Boosting</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="almond_ban"
        style={{
          backgroundImage: `url(${AldImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="shop_button">
          <Link to="/product/12" onClick={scrollToTop}>
            <button className="btn">SHOP NOW</button>
          </Link>
        </div>
      </div>

      <div className="coco_info">
        <div className="coco_info_head">
          <h1>Say farewell to table sugar you’ll never miss it </h1>
          <strong>
            Use it for baking, in your coffee, on your cereal and anywhere else
            you add sugar.
          </strong>
        </div>
        <div className="coco_info_des">
          <p>
            It’s tropical goodness is retained thanks to the way in which we
            preserve the healthy coconutty goodness from tree to table. It’s
            every bit as easy to bake and cook with and has a fantastic flavour
            and sweetness level on par with table sugar, but it’s got it all
            over that white stuff when it comes to health benefits. Use our
            coconut sugar, lose the table sugar – and life just gets sweeter.
          </p>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default Coconut;
