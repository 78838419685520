import React from "react";
import Newsletter from "../../components/Newsletter/Newsletter";
import "./Nut_Butters.scss";
import NutImg from "../../img/Nut_hero.webp";

const Nut_Butters = () => {
  return (
    <div className="max-width">
      <div className="nut_Butter">
        <div
          className="nut_hero"
          style={{
            backgroundImage: `url(${NutImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="nut_text">
            <h1>DID YOU KNOW ?</h1>
            <strong>
              Aprons ‘N’ Love nut butters will spread a whole new delicious
              nuttiness on all kinds of your favourite baking recipes.
            </strong>
            <br />
            <br />
            <p>
              If you’re a peanut butter nutter, we’ve got great news for you:
              Your world is about to explode with new flavours and ideas – big
              time. We’ve blended up four different types of nuts, pairing a few
              of them with a selection of other nuts and things for a delicious
              line of nut butters that will made you salivate at the
              possibilities. Spread them on bread, add them to recipes or just
              dip a spoon into a jar, and savour the flavour of purity and
              wholesome goodness of Aprons ‘N’ Love nut butters.
              <br />
              <br />
              Scan the QR code on top of the lid to open the door to open your
              world up to new baking and food-enhancement possibilities. Oh, and
              all our nut butters are fully plant-based, unsalted, gluten-free
              and non-GMO
            </p>
          </div>
          <div className="nut_no_text"></div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#006f62",
          }}
        >
          <div className="pro_img item_one">
            <img src={require("../../img/pistachio.webp")} alt="" />
          </div>
          <div className="pro_text item_two">
            <h1>Pistachio Butter</h1>
            <strong>There’s magic in the jar – but no added sugar</strong>
            <br />
            <p>
              Stash the pistachio butter, because few can resist its creamy
              allure. Made with pure pistachio nuts ground into a sublime
              spread, it’s delicious as a peanut butter substitute on bread or
              in recipes, and can be counted on to add a subtle but exotic taste
              and consistency to your classic baked treats.
            </p>
            <div className="Nut_shop">
              <a className="btn" href="/product/2">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#772585",
          }}
        >
          <div className="pro_text item_three">
            <h1>Peanut Butter</h1>
            <strong>A classic made nutty with love and simplicity</strong>
            <br />
            <p>
              Aprons ‘N’ Love peanut butter comes in two formats to suit
              different tastes – crunchy and smooth. Unlike many commercial
              peanut butters, it contains no sugar, just pure peanuts ground to
              perfection, ready to mix it up with jam for a scrumptious PB&J
              sanny or as an enhancement to banana bread or your fave cookie
              recipe.
            </p>
            <div className="Nut_shop">
              <a className="btn" href="/product/1">
                Shop Now
              </a>
            </div>
          </div>
          <div className="pro_img item_four">
            <img src={require("../../img/peanuts.webp")} alt="" />
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#Fc4c02",
          }}
        >
          <div className="pro_img item_five">
            <img src={require("../../img/Hazzaldnut.webp")} alt="" />
          </div>
          <div className="pro_text item_six ">
            <h1>Hazelnut Butter</h1>
            <strong>
              Hazelnut butter makes the world a more delicious place in which to
              bake
            </strong>
            <br />
            <p>
              You love nut butters, but we doubt you’ve ever had a spread quite
              as glorious as our hazelnut butter. We keep things pure and clean
              with no sugar added, and the flavour meter at 11. Drop a dollop in
              your next batch of dough and see where you end up. Once you start
              experimenting, it’s hard to stop. And why would you?
            </p>
            <div className="Nut_shop">
              <a className="btn" href="/product/3">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#66435a",
          }}
        >
          <div className="pro_text item_seven">
            <h1>Almond Butter </h1>
            <strong>The almighty almond packs a buttery blast</strong>
            <br />
            <p>
              Our rich and creamy almond butter has no added sugar, because
              almonds are so perfect in their natural form that no improvement
              is necessary... or even possible. Spread it on bread, add it to
              baking recipes or even savoury sauces and meals to elevate their
              flavour and consistency. Its subtlety and creaminess make taste
              buds dance with joy.
            </p>
            <div className="Nut_shop">
              <a className="btn" href="/product/4">
                Shop Now
              </a>
            </div>
          </div>
          <div className="pro_img itme_eight">
            <img src={require("../../img/almond.webp")} alt="" />
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#da1884",
          }}
        >
          <div className="pro_img item_nine">
            <img src={require("../../img/peanuts-2.webp")} alt="" />
          </div>
          <div className="pro_text item_ten">
            <h1>Peanut Butter</h1>
            <strong>A classic made nutty with love and simplicity</strong>
            <br />
            <p>
              Aprons ‘N’ Love peanut butter comes in two formats to suit
              different tastes – crunchy and smooth. Unlike many commercial
              peanut butters, it contains no sugar, just pure peanuts ground to
              perfection, ready to mix it up with jam for a scrumptious PB&J
              sanny or as an enhancement to banana bread or your fave cookie
              recipe.
            </p>
            <div className="Nut_shop">
              <a className="btn" href="/product/5">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#53cbed",
          }}
        >
          <div className="pro_text item_seven">
            <h1>Cashew Coconut Butter</h1>
            <strong>Cashew meets coconut. They elope.</strong>
            <br />
            <p>
              Cashew coconut butter is made with 75% cashews subtly enhanced
              with the succulence of coconut. It’s kinda sweet, kinda nutty, and
              fully scrumpy (that’s a word... look it up!). Simply stated, it’s
              a delicacy not to be missed, and to be added to your baked
              classics when you’re looking for that extra flavour zing. The
              magic of this combo cannot be overstated.
            </p>
          </div>
          <div className="pro_img itme_eight">
            <img src={require("../../img/Cashew.webp")} alt="" />
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#8f3237",
          }}
        >
          <div className="pro_img item_nine">
            <img src={require("../../img/Haxelnut-Cocoa.webp")} alt="" />
          </div>
          <div className="pro_text item_ten">
            <h1>Hazelnut cocoa butter</h1>

            <strong>
              Smother your tastebuds with smooth, heavenly hazelnut... with a
              twist
            </strong>
            <br />
            <p>
              Our hazelnut cocoa butter is more than just a tasty spread. We add
              just the right amount of tropical cocoa to enhance the flavour
              factor while giving it a sweet but healthy character and
              stand-alone flavour profile. It is unique in the marketplace of
              nut butters, and also happens to be vegan. Sandwich it or bake
              with it – you’ll keep discovering new ways to add it to your
              favourite treats.
            </p>
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#d77600",
          }}
        >
          <div className="pro_text item_seven">
            <h1>Almond Cashew butter</h1>
            <strong>Two nuts entangled in a rich and creamy affair</strong>
            <br />
            <p>
              Who’d have thought of it, but for the slightly demented minds at
              Aprons ‘N’ Love? Our almond cashew butter delivers a rich flavour
              combo that is nothing short of delectable. Whether on bread, in
              your baking as a secret ingredient or right off the end of a spoon
              and into your ever- lovin’ mouth, you’re going to look for new
              ways to include this stuff in your life.
            </p>
          </div>
          <div className="pro_img itme_eight">
            <img src={require("../../img/Almond-Cashew.webp")} alt="" />
          </div>
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Nut_Butters;
