import React from "react";
import "./Categories.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="categories">
      <div className="bigBoxOne">
        <Link to="products/6">
          <div className="img_con ">
            <img src={require("../../img/xl_pkg_2.webp")} />
          </div>
        </Link>
      </div>

      <div className="bigBoxTwo">
        <div className="img_con ">
          <Link to="products/3">
            <div className="img_con ">
              <img src={require("../../img/choco_pkt.webp")} />
            </div>
          </Link>
        </div>

        <div className="img_con ">
          <Link to="products/1">
            <div className="img_con ">
              <img src={require("../../img/peanuts-2.webp")} />
            </div>
          </Link>
        </div>

        <div className="img_con ">
          <Link to="products/7">
            <div className="img_con ">
              <img src={require("../../img/protine_ball.webp")} />
            </div>
          </Link>
        </div>

        <div className="img_con">
          <Link to="products/5">
            <div className="img_con ">
              <img src={require("../../img/s_03.webp")} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Categories;
