import React from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/mission-img.webp";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";
import SlideShow from "../../components/slideshow/SlideShow";
import Categories from "../../components/Categories/Categories";
import GoogleMap from "../../components/googleMap/GoogleMap";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div className="home">
        <div className="__hero">
          <SlideShow />
        </div>

        <div className="com_div">
          <div className="com_head">
            <h1>Top Selling Products</h1>
          </div>
          <FeaturedProducts type="featured" />
        </div>
        <Categories />


        <div className="com_div">
          <div>
            <h1>Trending Products</h1>
          </div>
          <FeaturedProducts type="tranding" />
        </div>

        
        <GoogleMap/>

      </div>
    </div>
  );
};

export default Home;
