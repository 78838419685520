import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./mobile.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);
    const [close, setClose] = useState(true);
  const products = useSelector((state) => state.cart.products);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="my-navbar">
      <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <div className="logo-top">
            <div>
              <button
                class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon top-bar"></span>
                <span class="toggler-icon middle-bar"></span>
                <span class="toggler-icon bottom-bar"></span>
              </button>
            </div>

            <div className="center">
              <Link className="link" to="/">
                <img src={require("../../img/logo_.webp")} alt="" />
              </Link>
            </div>

            <div className="item">
              <div className="icons">
                <div className="cartIcon" onClick={() => setOpen(!open)}>
                  <ShoppingCartIcon />
                  <span>{products.length}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link className="link" to="/products/1">
                  <span>Shop</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/offers">
                  <span> Offers</span>
                </Link>
              </li>

              <li class="nav-item">
                <Link className="link" to="/products/2">
                  <span>Arabian Delights</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/products/3">
                  <span>Chocodate</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/products/4">
                  <span> Wishes</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/products/6">
                  <span> XL</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/products/7">
                  <span> Raja</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link className="link" to="/products/8">
                  <span> Zein</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="wrapper">
        <div className="left">
          <div className="item">
            <Link className="link" to="/">
              <HomeIcon />
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/products/1">
              <span>Shop</span>
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/offers">
              <span> Offers</span>
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/products/2">
              <span>Arabian Delights</span>
            </Link>
          </div>

          <div className="item">
            <Link className="link" to="/products/3">
              <span>Chocodate</span>
            </Link>
          </div>
        </div>

        <div className="center">
          <Link className="link" to="/">
            <img src={require("../../img/logo_.webp")} alt="" />
          </Link>
        </div>

        <div className="right">
          <div className="item">
            <Link className="link" to="/products/4">
              <span> Wishes</span>
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/products/6">
              <span> XL</span>
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/products/7">
              <span> Raja</span>
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/products/8">
              <span> Zein</span>
            </Link>
          </div>

          <div className="item">
            <div className="icons">
              <div className="shoppingCart">
                <div className="cartIcon" onClick={() => setOpen(!open)}>
                  <ShoppingCartIcon />
                  <span>{products.length}</span>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {open && <Cart />}
    </div>
  );
};

export default Navbar;
