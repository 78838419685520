import React from "react";
import Newsletter from "../../components/Newsletter/Newsletter";
import "./Chocolate.scss";
import ChocoImg from "../../img/Chocolate_hero.webp";
import Product from "../Product/Product";
import { Link } from "react-router-dom";

const Chocolate = () => {
  return (
    <div className="max-width">
      <div className="choco_Butter">
        <div
          className="choco_hero"
          style={{
            backgroundImage: `url(${ChocoImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="choco_text">
            <h1>DID YOU KNOW ?</h1>
            <strong>HEALTHY PLANT-BASED PROTEIN SOURCE</strong>
            <br /> <br />
            <p>
              Chocolate chips just got a yum-tonne more interesting, thanks to
              Aprons ‘N’ Love. We have taken the old classic choco chip, made it
              cleaner, healthier and tastier. Then, not content to rest on our
              laurels, we went a little bonkers and added a bunch of variations
              on the theme, creating a whole new line of superb chocolate chips
              for baking, snacking or sneaking into foods and dishes they’ve not
              been seen in before. We leave that part up to your baker’s brain.
              BTW, these little flavour buddies are all-natural, gluten-free,
              vegetarian and preservative-free.
            </p>
          </div>
          <div className="choco_no_text"></div>
        </div>

        <div
          className="pro_section "
          style={{
            backgroundColor: "#00a499",
          }}
        >
          <div
            className="pro_img item_one"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={require("../../img/milk_choco.webp")} alt="" />
          </div>

          <div className="pro_text item_two" style={{}}>
            <h1>Milk Chocolate chips</h1>
            <strong>Classic, untainted, beloved by all</strong>
            <br />
            <p>
              The simple, superb chocolate chip, Aprons ‘N’ Love style. Meaning,
              no added stuff, just the cocoa goodness you need to make cookies
              sing and baked treats sing opera.
            </p>
            <div className="choco_shop">
              <a className="btn" href="/product/6">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <div
          className="pro_section "
          style={{
            backgroundColor: "#6d2077",
          }}
        >
          <div className="pro_text item_three ">
            <h1>Dark chocolate chips</h1>
            <strong>
              Ready to go dark? This is how to exoticize your bake
            </strong>
            <br />
            <p>
              Dark chocolate in chip form. What could be more refined, more
              elegant, more sophisticated? Not all that much, especially if
              you’re looking to add an uplifting addition to your baking
              regimen. We make it easy to be that baker – now go forth and
              create... in the darkness.
            </p>
            <div className="choco_shop">
              <a className="btn" href="/product/7">
                Shop Now
              </a>
            </div>
          </div>
          <div
            className="pro_img item_four"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={require("../../img/Dark_choco.webp")} alt="" />
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#F19C26",
          }}
        >
          <div
            className="pro_img item_five"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={require("../../img/Semi_choco.webp")} alt="" />
          </div>
          <div className="pro_text item_six">
            <h1>Semi-sweet chocolate chips</h1>
            <strong>
              There’s sweet, and then there’s semi-sweet. Be one or be both
            </strong>
            <br />
            <p>
              For decades, bakers of fine goodies have relied on the old
              standard of the semi-sweet chocolate chip. We took that notion one
              further with the added benefits of purity, simplicity and honest
              ingredients. Use it as you would any other semi-sweet, but content
              in the knowledge that you’re cooking with the finest chips on
              earth.
            </p>
            <div className="choco_shop">
              <a className="btn" href="/product/8">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "ed8b00",
          }}
        >
          <div
            className="pro_text item_seven"
            style={{
              backgroundColor: "#007398",
            }}
          >
            <h1>White chocolate chips</h1>
            <strong>
              Lighten it up with the fanciful twist that white chocolate brings
              to a bake
            </strong>
            <br />
            <p>
              You love chocolate. You love baking with chocolate. You love
              seeing the faces of those your love when you bake with chocolate.
              Now imagine throwing a twist into something beloved with the
              addition of white chocolate chips. Their flavour has a subtle
              difference, and the look they bring to the finished product is no
              less delightful. Bake with white, watch taste buds take flight!
            </p>
            <div className="choco_shop">
              <a className="btn" href="/product/9">
                Shop Now
              </a>
            </div>
          </div>
          <div
            className="pro_img itme_eight"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={require("../../img/white_choco.webp")} alt="" />
          </div>
        </div>

        <div
          className="pro_section"
          style={{
            backgroundColor: "#890c58",
          }}
        >
          <div
            className="pro_img item_nine"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={require("../../img/Ruby_choco.webp")} alt="" />
          </div>
          <div className="pro_text item_ten ">
            <h1>Ruby chocolate chips</h1>
            <strong>Time to ruby it up</strong>
            <br />
            <p>
              Imagine adding these sweet things to some of your go-to baking
              recipes. Cookies become stories. Cakes become exotic islands.
              Muffins become happiness-studded marvels of ovenly goodness.
              Imagination is your only limitation. So do your loved ones a
              favour and splash in some ruby flavour.
            </p>
            <div className="choco_shop">
              <a className="btn" href="/product/10">
                Shop Now
              </a>
            </div>
          </div>
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Chocolate;
