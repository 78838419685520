import React from 'react'
import FeaturedProducts from '../FeaturedProducts/FeaturedProducts';

const Offer = () => {
  return (
    <div className="offer">
      <FeaturedProducts type="offers" />
    </div>
  );
}

export default Offer
