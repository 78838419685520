import React from "react";
import "./Footer.scss";
import Copyright from "../Copyright/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import Pay_Icon from "../PaymentIcon/Pay_Icon";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer_wrap">
      <div className="footer">
        <div className="footer_my_container">
          <div className="footer_section_one">
            <h1>
              Follow us <br /> <span1>@Factory outlet</span1>
            </h1>
            <div className="social_icon">
              <a
                style={{ color: "white" }}
                href="https://www.facebook.com/Chocolateandsnacks.outlet"
              >
                <FacebookIcon />
              </a>

              <a
                style={{ color: "white" }}
                href="https://www.instagram.com/chocolateandsnacks.outlet/"
              >
                <InstagramIcon />
              </a>
            </div>
          </div>

          <div className="footer_list">
            <div>
              <ul>
                <li>
                  <Link className="link" to="/products/1">
                    <span>Shop</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/offers">
                    <span> Offers</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/products/2">
                    <span>Arabian Delights</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/products/3">
                    <span>Chocodate</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/products/4">
                    <span> Wishes</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/products/5">
                    <span>Luxury Chocolate</span>
                  </Link>
                </li>
                <li className="link">
                  <Link className="link" to="/products/6">
                    <span> XL</span>
                  </Link>
                </li>
                <li className="link">
                  <Link className="link" to="/products/7">
                    <span> Raja</span>
                  </Link>
                </li>
                <li className="link">
                  <Link className="link" to="/products/8">
                    <span> Zein</span>
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <Link to="/contact" onClick={scrollToTop}>
                    {" "}
                    <p>Contact</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={scrollToTop}>
                    {" "}
                    <p>About</p>{" "}
                  </Link>
                </li>

                <li>
                  <Link to="/terms-&-conditions" onClick={scrollToTop}>
                    {" "}
                    <p>T&C</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" onClick={scrollToTop}>
                    {" "}
                    <p>Privacy Policy</p>{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer_section_two">
            <a href="https://www.notionsgroup.com/">
              <div className="logo">
                <img src={require("../../img/w-logo.png")} />
              </div>
            </a>
            <p>
              Passion is the force that drives us; reason is what steers our
              endeavours. We put our hearts and our minds in every little detail
              of the business to achieve the best results. We are
            </p>
          </div>
        </div>
      </div>
      <Pay_Icon />
      <Copyright />
    </div>
  );
};

export default Footer;
